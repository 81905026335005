import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import {
  makeStyles
} from '@material-ui/styles';
import StyledButton from './StyledButton';
import { useUser } from '../context/AuthContext';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    borderColor: theme.palette.primary.main
  },
  bolded: {
    fontWeight: 600
  }
}));

const FileUploadHistory = () => {
  const { currentUser } = useUser();
  const s = useStyles();

  return (
    <Card className={s.container}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" gutterBottom className={s.bolded}>
            Upload History
          </Typography>
        </Grid>
        {
          currentUser && (
            currentUser.uploadHistory ? (
              <Grid item>
                <List dense disablePadding>
                  {
                    currentUser.uploadHistory.map(text => (
                      <ListItem disableGutters>
                        <ListItemText
                          primary={text}
                        />
                      </ListItem>
                    ))
                  }
                </List>
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  You have not uploaded any CSVs yet
                </Typography>
              </Grid>
            )
          )
        }
      </Grid>
    </Card>
  )
}

export default FileUploadHistory;
