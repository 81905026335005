import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useUser } from './AuthContext';

const AnalysisContext = createContext({
  nonOptedMembers: 0,
  setNonOpted: () => {},
  totalMembers: 0,
  setTotalUsers: () => {},
  optedMembers: 0,
  setOpted: () => {}
})

const AnalysisProvider = ({children, value = {}, ...rest}) => {
  const [nonOptedMembers, setNonOpted] = useState(value.nonOptedMembers || 0);
  const [totalMembers, setTotalUsers] = useState(value.totalMembers || 0);
  const [optedMembers, setOpted] = useState(value.optedMembers || 0);
  const { currentUser } = useUser();

  useEffect(() => {
    if (currentUser) {
      if (currentUser.nonOptedMembers) {
        setNonOpted(currentUser.nonOptedMembers);
      }
      if (currentUser.optedMembers) {
        setOpted(currentUser.optedMembers);
      }
      if (currentUser.totalContacts) {
        setTotalUsers(currentUser.totalContacts);
      }
    }
  }, [currentUser]);

  return (
    <AnalysisContext.Provider {...rest} value={{
      nonOptedMembers,
      setNonOpted,
      totalMembers,
      setTotalUsers,
      optedMembers,
      setOpted,
    }}>
      { children }
    </AnalysisContext.Provider>
  )
}

const useAnalysis = () => {
  const {
    nonOptedMembers,
    setNonOpted,
    totalMembers,
    setTotalUsers,
    optedMembers,
    setOpted
  } = useContext(AnalysisContext);
  return useMemo(() => {
    return ({
      nonOptedMembers,
      setNonOpted,
      totalMembers,
      setTotalUsers,
      optedMembers,
      setOpted
    })
  }, [
    nonOptedMembers,
    setNonOpted,
    totalMembers,
    setTotalUsers,
    optedMembers,
    setOpted
  ]);
}

export {
  AnalysisProvider,
  useAnalysis
};
