import {
  createMuiTheme
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5da857',
    },
    secondary: {
      main: '#fff'
    },
  },
  typography: {
    fontFamily: "\"Poppins\", Roboto, sans-serif"
  }
});

export default theme;
