import React, { Fragment } from 'react'
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer
} from 'recharts';
import {
  Typography,
  Card,
  Tooltip as MuiTooltip,
  IconButton,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {
  makeStyles
} from '@material-ui/styles';
import {
  useTheme
} from '@material-ui/core/styles';
import {
  useAnalysis
} from '../context/AnalysisContext';
import StyledLink from './StyledLink';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    marginBottom: 36,
    borderColor: theme.palette.primary.main
  },
  bolded: {
    fontWeight: 600,
    marginBottom: 20
  },
  icon: {
    width: 25,
    height: 25,
    marginLeft: 5
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9',
  }
}));

const Chart = () => {
  const s = useStyles();
  const {
    nonOptedMembers,
    totalMembers,
    optedMembers
  } = useAnalysis();
  const theme = useTheme();
  const colors = [
    '#ddd',
    '#f3be40',
    theme.palette.primary.main
  ]

  const data = [
    {
      "type": "Your Contacts",
      "Amount": totalMembers
    },
    {
      "type": "Circle Members",
      "Amount": optedMembers + nonOptedMembers
    },
    {
      "type": "Opted Members",
      "Amount": optedMembers
    }
  ]
  return (
    <Card className={s.container}>
      <Typography variant="h5" gutterBottom className={s.bolded}>
        Report
        <MuiTooltip placement="right" classes={{tooltip: s.tooltip}} title={
          <React.Fragment>
            <Typography variant="body2">
            {
              `This chart displays which of your contacts are in the Circle Base. `
            }
              <StyledLink href="https://circleenvhelp.freshdesk.com/support/solutions/articles/64000228187-what-does-the-chart-mean-/" target="_blank">
              {`Click here `}
              </StyledLink>
            {
              `to learn more.`
            }
            </Typography>
          </React.Fragment>
        } interactive>
          <IconButton size="small" aria-label="delete" className={s.icon}>
            <HelpIcon style={{width: '100%'}}/>
          </IconButton>
        </MuiTooltip>
      </Typography>
      <ResponsiveContainer width="100%" height={260}>
        <BarChart data={data} barSize={45}>
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Amount">
            {
              data.map((_, index) => (
                <Cell key={`cell-${index}`} stroke={colors[index]} fill={colors[index]} stroke="transparent" />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default Chart
