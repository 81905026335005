import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardActionArea
} from '@material-ui/core';
import {
  makeStyles
} from '@material-ui/styles';
import StyledButton from './StyledButton';
import StyledLink from './StyledLink';
import { useUser } from '../context/AuthContext';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  useModal
} from '../context/ModalContext';

const useStyles = makeStyles(theme => ({
  container: {
    borderColor: theme.palette.primary.main,
    position: 'relative',
    minHeight: 190
  },
  bolded: {
    fontWeight: 600
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    zIndex: 5,
    pointerEvents: 'all',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  grid: {
    padding: 20
  }
}));

const DownloadView = () => {
  const { currentUser } = useUser();
  const {
    handleOpenDialog
  } = useModal();
  const s = useStyles();
  const isPremium = currentUser && currentUser.membership_level === 'premium';

  return (
    <Card className={s.container}>
      <Grid container direction="column" spacing={2} className={s.grid}>
        <Grid item>
          <Typography variant="h5" gutterBottom className={s.bolded}>
            Download Report
          </Typography>
        </Grid>
        {
          currentUser && currentUser.membership_level === 'premium' && currentUser.downloadLink && (
            <React.Fragment>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  {
                    `Click here to download the list of Opted Members in your most recent upload. For more information, `
                  }
                  <React.Fragment>
                    <StyledLink target="_blank" href="https://www.circleenv.com/base/pricing">click here</StyledLink>
                  </React.Fragment>
                </Typography>
              </Grid>
              <Grid item>
                <StyledButton variant="contained" color="primary" component="a" href={currentUser.downloadLink} target="_blank" startIcon={<CloudDownloadIcon />}>
                  Download Most Recent Analysis
                </StyledButton>
              </Grid>
            </React.Fragment>
          )
        }
      </Grid>
      {
        currentUser && !isPremium && (
          <div className={s.overlay}>
            <Typography variant="h5" gutterBottom className={s.bolded}>
              Upgrade to download
            </Typography>
            <Typography variant="body2" gutterBottom style={{marginBottom: 15}}>
              Premium users can view their opted members and more
            </Typography>
            <StyledButton variant="contained" color="primary" onClick={handleOpenDialog} startIcon={<CloudDownloadIcon />}>
              Upgrade Now
            </StyledButton>
          </div>
        )
      }
    </Card>
  )
}

export default DownloadView;
