import axios from 'axios';
// constructor() {
//     this._axios_instance = axios.create({
//       baseURL: 'https://us-central1-circle-project-base.cloudfunctions.net/app',
//     })
//   }

class Request {
  static _instance = null;
  constructor() {
    this._axios_instance = axios.create({
      baseURL: 'https://us-central1-circle-project-base.cloudfunctions.net/app',
    })
    // this._axios_instance = axios.create({
    //   baseURL: 'http://localhost:5000/circle-project-base/us-central1/app',
    // })
  }

  static instance() {
    if (Request._instance === null) {
      Request._instance = new Request();
    }
    return Request._instance;
  }

  async request({
      method,
      data,
      url
  }, currentUser = {}) {
    return await this._axios_instance.request({
      method,
      data,
      url,
      headers: {
        'X-Auth-User': JSON.stringify(currentUser),
        'Content-Type': 'application/json'
      }
    })
  };
}

export default Request.instance();
