import React, { createContext, useContext, useState, useMemo } from 'react';

const UserStateContext = createContext({
  user: null,
  setUser: () => {}
})

const UserStateProvider = ({children, value = {}, ...rest}) => {
  const [user, setUser] = useState(value.user || null);

  return (
    <UserStateContext.Provider {...rest} value={{ user, setUser }}>
      { children }
    </UserStateContext.Provider>
  )
}

const useUser = () => {
  const { user: currentUser, setUser } = useContext(UserStateContext);
  return useMemo(() => {
    return ({
      currentUser,
      setUser
    })
  }, [currentUser, setUser]);
}

export {
  UserStateProvider,
  useUser
};
