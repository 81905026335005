import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Card
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {
  useSnackbar
} from 'notistack';
import {
  makeStyles
} from '@material-ui/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Request from '../utils/Request';
import { useUser } from '../context/AuthContext';
import Papa from 'papaparse';
import StyledButton from './StyledButton';
import StyledLink from './StyledLink';
import {
  DropzoneArea
} from 'material-ui-dropzone';
import { useAnalysis } from '../context/AnalysisContext';
import { useModal } from '../context/ModalContext';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    marginBottom: 40,
    borderColor: theme.palette.primary.main,
  },
  dropZone: {
    minHeight: '180px'
  },
  downloadButton: {
  },
  bolded: {
    fontWeight: 600
  },
  icon: {
    width: 25,
    height: 25,
    marginLeft: 5
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9',
  }
}));

const FileUploader = () => {
  const { currentUser, setUser } = useUser();
  const s = useStyles();
  const [file, setFile] = useState(null);
  const [requestPromises, setPromises] = useState([]);
  const {
    enqueueSnackbar,
    closeSnackbar
  } = useSnackbar();
  const {
    setNonOpted,
    setTotalUsers,
    setOpted
  } = useAnalysis();
  const [downloadLink, setDownloadLink] = useState(null)
  const isPremium = currentUser && currentUser.membership_level === 'premium';
  const {
    handleOpenDialog
  } = useModal();

  const handleChange = files => {
    closeSnackbar();
    setFile(files[0]);
  }

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file])

  const uploadFile = () => {
    if (file == null) {
      enqueueSnackbar('Please select a file first', { variant: 'error'})
    } else {
      closeSnackbar();
      Papa.parse(file, {
        chunk: (results, parser) => {
          setPromises(prevPromises => {
            console.log(JSON.stringify(results.data))
            prevPromises.push(
              Request.request({
                url: '/data-analysis/',
                data: JSON.stringify(results.data),
                method: 'post'
              }, currentUser)
            )
            return prevPromises;
          })
        },
        complete: (results, file) => {
          Promise.all(requestPromises).then(allData => {
            setOpted(0);
            setNonOpted(0);
            setTotalUsers(0);
            allData.forEach(e => {
              const { data } = e;
              const { opted, total, nonOpted, csvLink } = data;
              setOpted(prev => prev + opted);
              setNonOpted(prev => prev + nonOpted);
              setTotalUsers(prev => prev + total);
              if (csvLink && currentUser.membership_level === 'premium') {
                setDownloadLink(csvLink);
              }
              Request.request({
                url: '/users/',
                data: {
                  email: currentUser.email,
                  data: {
                    optedMembers: opted,
                    nonOptedMembers: nonOpted,
                    downloadLink: csvLink,
                    totalContacts: total,
                    metaData: `${(new Date()).toLocaleString()} ${file.name}`
                  }
                },
                method: 'post'
              }, currentUser).then(res => {
                const { data } = res;
                setUser(data);
              })
            })
            enqueueSnackbar('Analysis completed.', { variant: 'success'})
            setPromises([]);
          }).catch(e => {
            console.log(e);
            enqueueSnackbar('Badly formatted CSV, please try again.', { variant: 'error' })
          })
        }
      })
    }
    }

  return (
    <Card raised className={s.container}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" gutterBottom className={s.bolded}>
            Upload File
            <Tooltip placement="right" classes={{tooltip: s.tooltip}} title={
              <React.Fragment>
                <Typography variant="body2">
                {`Upload a single-column CSV file with your customer emails. `}
                  <StyledLink href=" https://circleenvhelp.freshdesk.com/en/support/solutions/articles/64000228202-uploading-contacts-to-circle-base/" target="_blank">
                  {`Click here `}
                  </StyledLink>
                {`for a tutorial.`}
                </Typography>
              </React.Fragment>
            } interactive>
              <IconButton size="small" aria-label="delete" className={s.icon}>
                <HelpIcon style={{width: '100%'}}/>
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography variant="body2">
            {
              `The maximum file size of the CSV is ${isPremium ? 10 : 1} MB.`
            }
            {
              !isPremium && (
                <React.Fragment>
                  <span>
                    To upgrade, please click&nbsp;
                  </span>
                  <StyledLink onClick={handleOpenDialog}>here</StyledLink>
                </React.Fragment>
              )
            }
          </Typography>
        </Grid>
        <Grid item>
          <DropzoneArea
            classes={{
              root: s.dropZone
            }}
            showPreviewsInDropzone={false}
            acceptedFiles={['application/vnd.ms-excel', 'text/plain', 'text/csv']}
            filesLimit={1}
            showFileNamesInPreview
            maxFileSize = {
              isPremium ? 10000000 : 1000000
            }
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          {
            downloadLink && isPremium && (
              <StyledButton variant="contained" color="primary" className={s.downloadButton} component="a" href={downloadLink} target="_blank" startIcon={<CloudDownloadIcon />}>
                Download Result
              </StyledButton>
            )
          }
        </Grid>
      </Grid>
    </Card>
  )
}

export default FileUploader;
