import React, { createContext, useContext, useState, useMemo } from 'react';

import {
  makeStyles,
  Dialog,
  Typography
} from '@material-ui/core'
import CardInputForm from '../components/CardInputForm';
import StyledLink from '../components/StyledLink';

const useStyles = makeStyles(theme => ({
  logo: {
    height: '50px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  dialog: {
    padding: 20
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 25
  },
  subTitle: {
    fontSize: 15,
    margin: '15px 0px 25px 0px',
    textAlign: 'center',
    color: 'grey',
  },
  titleLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const ModalContext = createContext({
  openDialog: null,
  setUser: () => {}
})

const ModalProvider = ({children, value = {}, ...rest}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const s = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }
  return (
    <ModalContext.Provider {...rest} value={{ openDialog, handleCloseDialog, handleOpenDialog }}>
      { children }
      <Dialog onClose={handleCloseDialog} open={openDialog} className={`card`} classes={{paper: s.dialog}}>
        <Typography className={s.title}>
          Upgrade to premium for $99/month
        </Typography>
        <Typography className={s.subTitle}>
          {
            `Unlock the ability to download the list of your contacts that want to support you in your sustainability journey. `
          }
          <StyledLink href="https://www.circleenv.com/base/pricing/" target="_blank">
            Click here
          </StyledLink>
          {
            ` for information on pricing and features.`
          }
        </Typography>
        <CardInputForm />
      </Dialog>
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const {
    openDialog,
    handleCloseDialog,
    handleOpenDialog
  } = useContext(ModalContext);
  return useMemo(() => {
    return ({
      openDialog,
      handleCloseDialog,
      handleOpenDialog
    })
  }, [
    openDialog,
    handleCloseDialog,
    handleOpenDialog
  ]);
}

export {
  ModalProvider,
  useModal
};
