import React, { useState } from 'react'
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  MenuItem,
  IconButton,
  Menu,
  Grid,
  Dialog,
  makeStyles
} from '@material-ui/core'

import AccountCircle from '@material-ui/icons/AccountCircle';
import { useUser } from '../context/AuthContext';
import { useFirebase } from '../hooks/useFirebase';
import { useModal } from '../context/ModalContext';

const useStyles = makeStyles(theme => ({
  logo: {
    height: '50px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  dialog: {
    padding: 20
  },
  title: {
    fontWeight: 600,
    fontSize: 25
  },
  subTitle: {
    fontSize: 15,
    margin: '20px 0px 10px 0px',
    textAlign: 'center',
    color: 'grey',
  },
  titleLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const AppBar = () => {
  const c = useStyles();
  const { currentUser, setUser } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    handleOpenDialog
  } = useModal();
  const [firebase] = useFirebase();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await firebase.auth().signOut();
    setUser(null);
    handleClose();
  }

  const handleUpgradeToPremium = () => {
    handleOpenDialog();
    handleClose();
  }

  return (
    <React.Fragment>
      <MuiAppBar position="static" color="secondary">
        <Toolbar className={c.toolbar}>
          <IconButton edge="start" color="inherit" aria-label="menu" component="a" disableElevation disableRipple href="https://www.circleenv.com/" classes={{root: c.logo}}>
            <img src="https://uploads-ssl.webflow.com/5d8d45bd8f129f4678e41570/5d8f8d2fae00229e03552488_Header%20Logo.png" height="50" />
          </IconButton>
          {
            currentUser && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  {
                    currentUser.membership_level != 'premium' && (
                      <MenuItem onClick={handleUpgradeToPremium}>Upgrade To Premium</MenuItem>
                    )
                  }
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )
          }
        </Toolbar>
      </MuiAppBar>
    </React.Fragment>
  )
}

export default AppBar
