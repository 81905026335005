import React from 'react'
import {
  Grid,
  Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import FileUploader from '../components/FileUploader';
import FileUploadHistory from '../components/FileUploadHistory';
import DownloadView from '../components/DownloadView';
import Chart from '../components/Chart';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20
  }
}));

const AnalyticsDashboardView = () => {
  const s = useStyles();

  return (
    <Container className={s.container}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <FileUploader />
          <FileUploadHistory />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Chart />
          <DownloadView />
        </Grid>
      </Grid>
    </Container>
  )
}

export default AnalyticsDashboardView;
