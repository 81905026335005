import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white'
  }
}));

const StyledButton = ({children, className, ...rest}) => {
  const s = useStyles();
  return (
    <Button raised {...rest} className={`${className} ${s.button}`}>
      { children }
    </Button>
  )
}

export default StyledButton;
