import React from 'react';
import Dashboard from './pages/Dashboard';
import { UserStateProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';
import { AnalysisProvider } from './context/AnalysisContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from './theme/primaryTheme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};


function App() {
  return (
    <div className="App">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <UserStateProvider>
          <AnalysisProvider value={{}}>
            <SnackbarProvider maxStack={3}>
              <ThemeProvider theme={Theme}>
                <ModalProvider>
                  <Dashboard />
                </ModalProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </AnalysisProvider>
        </UserStateProvider>
      </Elements>
    </div>
  );
}

export default App;
