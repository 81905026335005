import React, { useEffect } from 'react'
import { useFirebase } from '../hooks/useFirebase';
import Request from '../utils/Request';
import qs from 'query-string';
import SignupForm from '../components/SignupForm';
import AppBar from '../components/AppBar';
import AnalyticsDashboardView from '../views/AnalyticsDashboardView';
import {
  useUser
} from '../context/AuthContext';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: props => ({
    backgroundColor: props.signup ? 'white' : theme.palette.primary.main,
    minHeight: '100vh'
  })
}));

const Dashboard = () => {
  const [firebase, user] = useFirebase();
  const { currentUser, setUser } = useUser();
  console.log(currentUser);
  const s = useStyles({signup: currentUser == null});

  useEffect(() => {
    console.log('user')
    console.log(user)
    console.log(firebase)
    if (firebase && !user) {
      console.log('fired once')
      const queryStrings = qs.parse(window.location.search);
      // Confirm the link is a sign-in with email link.

      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        var email =
          window.localStorage.getItem('emailForSignIn') ||
          queryStrings['email'];
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            const { user } = result;
            user.getIdToken(true).then(async idToken => {
              const result = await Request.request({
                url: '/login',
                data: {
                  user,
                  idToken
                },
                method: 'post'
              })
              const { user: circleUser } = result.data;
              if (circleUser && circleUser.id) {
                setUser(circleUser);
              }
            });
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch(function (error) { });
      }
    }
    if (firebase && user) {
      console.log('fired once again')
      user.getIdToken(true).then(async idToken => {
        const result = await Request.request({
          url: '/login/',
          data: {
            user,
            idToken
          },
          method: 'post'
        })
        const { user: circleUser } = result.data;
        if (circleUser && circleUser.id) {
          setUser(circleUser);
          console.log(circleUser)
        }
      });
    }
  }, [firebase]);


  return (
    <Grid container direction="column" className={s.container}>
      <AppBar />
      {
        user ? (
          <AnalyticsDashboardView />
        ) : (
          <SignupForm />
        )
      }
    </Grid>
  )
}

export default Dashboard;
