import React from 'react'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer'
    }
  }
}));

const StyledLink = ({children, className, ...rest}) => {
  const s = useStyles();
  return (
    <a {...rest} className={`${className} ${s.link}`}>
      { children }
    </a>
  )
}

export default StyledLink;
