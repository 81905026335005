import React, { useState, useCallback } from 'react';
import {
  TextField,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFirebase } from '../hooks/useFirebase';
import StyledLink from './StyledLink';

const useStyles = makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 300px)',
    fontFamily: theme.typography.fontFamily
  },
  textField: {
    maxWidth: '400px',
    width: '100%'
  },
  row: {
    width: '100%',
    textAlign: 'center'
  },
  button: {
    width: 200,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    textAlign: 'center'
  },
  subTitle: {
    fontSize: 17,
    margin: '20px 0px 10px 0px',
    textAlign: 'center',
    color: 'grey'
  },
  titleLink: {
    color: theme.palette.primary.main
  }
}));

const useChecked = () => {
  const [checked, setChecked] = useState(false);
  const toggleChecked = useCallback(() => {
    setChecked(prevChecked => !prevChecked);
  }, [checked]);

  return {
    checked,
    toggleChecked,
  };
};

const SignupForm = () => {
  const [firebase] = useFirebase();
  const [email, setEmail] = useState('');
  const { checked, toggleChecked } = useChecked();
  const [loginMode, setLoginMode] = useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const s = useStyles();

  const toggleLoginMode = () => {
    setLoginMode(prev => !prev);
  }
  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitted(true);
    try {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${window.location.protocol}//${window.location.host}/?email=${email}`,
        // This must be true.
        handleCodeInApp: true,
      };
      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function() {
          console.log('sucess');
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Container component="main" maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Grid
          className={s.container}
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            {
              submitted ? (
                <React.Fragment>
                  <Typography className={s.title}>
                    Please check your email
                  </Typography>
                  <Typography className={s.subTitle}>
                    Click the link in the email we sent you to login
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography className={s.title}>
                    {
                      loginMode
                      ? 'Welcome back!'
                      : 'Get Started With Circle Base'
                    }
                  </Typography>
                  <Typography className={s.subTitle}>
                  {
                    loginMode
                    ? "Don't have an acount?"
                    : 'Already have an account?'
                  }&nbsp;
                    <StyledLink onClick={e => {
                      e.preventDefault();
                      toggleLoginMode();
                    }} href="#" className={s.titleLink}>
                      {
                        loginMode
                          ?
                          "Sign up" :
                          'Login'
                      }
                    </StyledLink>
                  </Typography>
                </React.Fragment>
              )
            }
          </Grid>
          {!submitted && (
            <React.Fragment>
              <Grid item className={s.row}>
                <TextField
                  name="email"
                  className={s.textField}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  required
                  label="Email"
                  variant="outlined"
                />
              </Grid>
              {
                !loginMode && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={checked}
                          onChange={toggleChecked}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {`I agree to the `}
                          <StyledLink target="_blank" href="https://www.circleenv.com/base/terms">
                            terms and condition
                          </StyledLink>
                        </Typography>
                      }
                    />
                  </Grid>
                )
              }
              <Grid item>
                <Button
                  type="submit"
                  classes={{ root: s.button }}
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default SignupForm;
