import React from 'react';
import { useUser } from '../context/AuthContext';
import * as firebase from 'firebase/app';
import "firebase/auth";

export function useFirebase() {
  const [state, setState] = React.useState([null /* firebase client */ , undefined /* firebase user */ ]);
  const { currentUser, setUser } = useUser();

  React.useEffect(() => {
    console.log(currentUser, setUser)
    if (setUser) {
      // Initialize Firebase Client SDK
      if (!firebase.apps.length) {
        firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_API));
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      }

      // Save the current Firebase user to the component's state
      firebase.auth().onAuthStateChanged(user => {
        if (
          JSON.stringify(state[1] && state[1].toJSON()) !==
            JSON.stringify(user && user.toJSON()) ||
          state[0] !== firebase
        ) {
          const newState = [firebase, user || firebase.auth().currentUser];
          setUser(newState[1])
          setState(newState);
        }
      });
    }
  }, [firebase, setUser]);
  return state;
}
